var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 black",attrs:{"fluid":"","dark":""}},[_c('v-row',{staticClass:"pa-0 ma-0 pb-0 justify-center"},[_c('v-col',{staticClass:"pa-0 text-center white--text",attrs:{"cols":"12","md":"6","order":_vm.$vuetify.breakpoint.smAndDown ? 1 : 0}},[_c('v-img',{attrs:{"src":_vm.getImg(_vm.sobre),"min-height":"100%"}})],1),_c('v-col',{staticClass:"pa-9 py-16 ma-0 background white--text text-left",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-h5 grey--text text--darken-3",class:{
            '': _vm.$vuetify.breakpoint.smAndDown,
            'pt-4': _vm.$vuetify.breakpoint.mdAndUp,
          },staticStyle:{"line-height":"1.5"}},[_c('span',{staticClass:"raleway"},[_vm._v("Prezamos por estratégias criativas e eficazes aos clientes, contando com uma equipe de profissionais experientes.")])]),_c('div',{staticClass:"text-h5 grey--text text--darken-3",class:{
            'text-subtitle-1 pt-4': _vm.$vuetify.breakpoint.smAndDown,
            'text-subtitle-1 pt-6 pr-6': _vm.$vuetify.breakpoint.mdAndUp,
          },staticStyle:{"line-height":"1.7"}},[_c('span',{staticClass:"raleway"},[_vm._v("Nossa equipe é composta por profissionais multidisciplinares com sólida formação, contemplando conhecimento em diversas áreas do Direito.")])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }