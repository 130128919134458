<template>
  <div class="black pa-0 white--text" dark style="">
    <v-carousel
      class="pa-0 ma-0"
      cycle
      continuous
      hide-delimiters
      :show-arrows="false"
      :interval="interval"
      height="100%"
    >
      <v-carousel-item class="pa-0 ma-0" style="">
        <!-- <v-parallax
          :src="getImg(capa)"
          v-bind="heightParallax"
          height="100%"
        > -->
        <v-parallax
          :src="getImg(capa)"
          max-height="50%"
        >
          <div style="
              background-color: rgba(34,53,79, 0.75);
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
          "></div>
          <v-row 
            class="pt-16 justify-center white--text"
            style="position: relative"
          >
            <v-col 
              cols="11" md="10"
              class="pt-16 pb-16 text-left"
            >
              <!-- <div class="pt-0 mx-auto">
                <v-img
                  class="mx-auto"
                  contain
                  :src="getImg(logo)"
                  transition="scale-transition"
                  width="270"
                />
              </div> -->
              <!-- <div class="pt-16 pb-12 mx-auto">
                <v-img
                  class="mx-auto pb-16"
                  contain
                  :src="getImg(logo)"
                  transition="scale-transition"
                  width="220"
                />
              </div> -->

              <div class="pt-8 mt-16 text-h6" style="line-height: 1.4">
                <span class="open-sans font-weight-medium">Defendemos uma sociedade justa, uma advocacia moderna e um </span>
              </div>

              <div class="pt-1 text-h2" style="line-height: 1">
                <span class="open-sans font-weight-light primary--text">atendimento humanizado</span>
              </div>
              
              <div class="pt-12 pb-16 mb-16 mb-12">
                <v-btn 
                  x-large
                  outlined
                  class="py-0 my-0 px-6 text-h6"
                  color="primary black--text"
                  @click="$vuetify.goTo('#contato', { offset: 0 })"
                >
                  <span class="font-weight-regular">Entrar em Contato</span>
                </v-btn>
              </div>

            </v-col>
          </v-row>
        </v-parallax>
      </v-carousel-item>
    </v-carousel>

  </div>
</template>

<script>
// import Contador from '../components/Contador'
// import Inscricao from '../components/Inscricao'

export default {
  components: {
    // Contador,
    // Inscricao
  },

  //props: ['endtime'],

  data: () => ({
    capa: 'bg-capa.jpg',
    logo: 'logo.png',
    interval: 6000,
  }),

  computed: {
    heightParallax () {
      if (this.$vuetify.breakpoint.smAndDown) {
        return { height: '100%' }
      }
      else{
        return null
      }
    }
  },

  methods: {
    getImg(i) {
      return require('../assets/img/'+i)
    },
  },

  mounted() {
    
  }
}
</script>
