<template>
  <div>
    <navbar/>
    <v-main>
      <capa endtime="endtime"/>
      <sobre/>
      <areas/>
      <equipe/>
      <links/>
      <!-- <parallax/> -->
      <imagem-livre/>
      <contato :whatsapp="whatsapp" />
      <!-- <rodape/> -->
      <lgpd/>
      <whats-app :whatsapp="whatsapp" />
    </v-main>
  </div>
</template>

<script>
  import Navbar from '../components/Navbar'
  import Capa from '../components/Capa'
  import Sobre from '../components/Sobre'
  import Areas from '../components/Areas'
  import Equipe from '../components/Equipe'
  import Links from '../components/Links'
  //import Parallax from '../components/Parallax'
  import ImagemLivre from '../components/ImagemLivre'
  import Contato from '../components/Contato'
  //import Rodape from '../components/Rodape'
  import Lgpd from '../components/Lgpd'
  import WhatsApp from '../components/WhatsApp'

  export default {
    name: 'HomeView',

    components: {
      Navbar,
      Capa,
      Sobre,
      Areas,
      Equipe,
      Links,
      //Parallax,
      ImagemLivre,
      Contato,
      //Rodape,
      Lgpd,
      WhatsApp
    },

    data: () => ({
       //endtime: 'Jun 27, 2022 19:00:00',
       whatsapp: 'https://api.whatsapp.com/send?phone=5584998930544&text=Ol%C3%A1!%20Estou%20vindo%20do%20site%20Borges%20%26%20Barreto%20e%20gostaria%20de%20agendar%20uma%20consulta.'
    })
    
  }
</script>
