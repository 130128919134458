<template>
  <v-container fluid class="py-16 background" dark>

    <v-row class="pt-12 justify-center">
      <v-col cols="12" md="8" class="text-center">
        <div class="text-h4">
          <span class="poppins secondary--text font-weight-medium">Links Úteis</span>
        </div>
      </v-col>
    </v-row>

    <v-row 
      class="pt-9 pb-16 px-6 justify-center white--text"
    >
      <v-col
        cols="12"
        md="8"
      >

        <v-list class="transparent">
          <v-list-item
            v-for="(l,i) in links"
            :key="i"
            :href="l.link"
            target="_blank"
            class="secondary--text font-weight-bold"
          >
            <v-icon class="pr-2 grey--text">mdi-earth</v-icon>
            <v-list-item-title v-text="l.nome"></v-list-item-title>
            <!-- <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon> -->
          </v-list-item>
          <v-list-group :value="false" class="secondary--text font-weight-bold">
            <template v-slot:activator>
              <!-- <v-list-item-title>Users</v-list-item-title> -->
              
              <v-list-item-content class="secondary--text font-weight-bold">
                
                <v-list-item-title class=""><v-icon class="pr-1 grey--text">mdi-earth</v-icon> Certidões</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(c,i) in certidoes"
              :key="i"
              :href="c.link"
              class="secondary--text font-weight-bold"
            >
              <v-icon class="pr-2 grey--text">mdi-earth</v-icon>
              <v-list-item-title class="secondary--text font-weight-regular" v-text="c.nome"></v-list-item-title>
              <!-- <v-list-item-icon>
                <v-icon>mdi-exit-to-app</v-icon>
              </v-list-item-icon> -->
            </v-list-item>
          </v-list-group>
        </v-list>

        <!-- <v-row>
          <v-col 
            cols="12"
            md="6"
            class="pt-10 text-left"
            v-for="(l,i) in links"
            :key="i"
          >
            <v-sheet rounded style="border: 1px solid #D1D1D1">
              <v-card flat tile color="background" class="pa-2" :href="l.link" target="_blank">
                <div class="text-subtitle-1 secondary--text font-weight-medium">
                  <div class="d-inline pr-2"><span><v-icon medium color="secondary">mdi-earth</v-icon></span></div>
                  <div class="d-inline"><span>(84) 99665-2333</span></div>
                  <div class="d-inline"><span>{{ l.nome }}</span></div>
                </div>
              </v-card>
            </v-sheet>
          </v-col>
        </v-row> -->
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

export default {

  data: () => ({
    links: [
      {
        nome: 'TJRN - Consulta Processual',
        link: 'https://www.tjrn.jus.br/acesso-rapido/consulta-processual'
      },
      // {
      //   nome: 'SEC Natal',
      //   link: 'https://secnatal.com.br'
      // },
      {
        nome: 'TRF - Consulta Processual',
        link: 'http://www5.trf5.jus.br/cp/'
      },
      {
        nome: 'PJE TRT - Consulta Processual',
        link: 'https://pje.trt21.jus.br/consultaprocessual/'
      },
    ],
    certidoes: [
      {
        nome: 'Certidão de Falência e Concordata do RN',
        link: 'http://esaj.tjrn.jus.br/esaj/portal.do?servico=810000'
      },
      {
        nome: 'CND de Débitos Trabalhistas (TST)',
        link: 'https://www.tst.jus.br/certidao1'
      },
      {
        nome: 'CND Municipal – Parnamirim',
        link: 'https://www.tinus.com.br/csp/PARNAMIRIM/portal/index.csp?656ANjD3104RcEWq40607hhaq4345LR=ZrNJ99KhU975Usp13457mmTgT255Pwhmt4713x6616658CWny586'
      },
      {
        nome: 'CND Municipal – Natal',
        link: 'https://natal.rn.gov.br/semut/'
      },
      {
        nome: 'CND Estadual e Dívida Ativa do Estado do RN (CONJUNTAS)',
        link: 'https://uvt2.set.rn.gov.br/#/services/certidao-negativa/emitir'
      },
      {
        nome: 'CND da Receita Federal, Dívida Ativa da União e INSS (CONJUNTAS)',
        link: 'https://solucoes.receita.fazenda.gov.br/Servicos/certidaointernet/PJ/Emitir'
      },
      {
        nome: 'CRF – Certificado de Regularidade do FGTS',
        link: 'https://consulta-crf.caixa.gov.br/consultacrf/pages/consultaEmpregador.jsf'
      },
      {
        nome: 'Consulta de opção pelo SIMPLES',
        link: 'http://www8.receita.fazenda.gov.br/SIMPLESNACIONAL/aplicacoes.aspx?id=21'
      },
      {
        nome: 'Consulta CNPJ',
        link: 'http://servicos.receita.fazenda.gov.br/Servicos/cnpjreva/Cnpjreva_Solicitacao.asp'
      },
      // LINK QUEBRADO
      // {
      //   nome: 'Consulta a cadastro estadual – RN',
      //   link: 'http://www.set.rn.gov.br/uvt/consultacontribuinte.aspx?servico=consulta'
      // },
      {
        nome: 'Consulta a cadastro municipal – NATAL',
        link: 'https://directa.natal.rn.gov.br/'
      },
      {
        nome: 'Certidão de Falência e Concordata – PB',
        link: 'https://www.tjpb.jus.br/'
      },
      {
        nome: 'CND Municipal – João Pessoa',
        link: 'https://joaopessoa.pb.gov.br/pc/certidaoNegativa.xhtml'
      },
      {
        nome: 'CND Estadual – PB',
        link: 'https://saplic.receita.pb.gov.br/atf/seg/SEGf_AcessarFuncao.jsp?cdFuncao=DIA_060'
      },
      {
        nome: 'Certidão Consolidada de Idoneidade',
        link: 'https://certidoes-apf.apps.tcu.gov.br/'
      },
      // LINK QUEBRADO
      // {
      //   nome: 'Emissão da certidão da Corregedoria de Justiça',
      //   link: 'https://certidao.tjrn.jus.br/certidaounificada/f/public/validacao.xhtml'
      // },
    ]
  }),

  methods: {
    getImg(i) {
      return require('../assets/img/'+i)
    },
  },
}
</script>
