import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        //primary: '#CFC093', // Amarelo
        primary: '#E0DBB5', // Amarelo
        secondary: '#22354F', // Dark blue
        tertiary: '#22354F',
        background: '#F1EDE1',
        // accent: '#82B1FF',
        // error: '#FF5252',
        // info: '#2196F3',
        // success: '#4CAF50',
        // warning: '#FFC107',
        whatsapp: '#00BA25'
      },
    },
  },
    lang: {
      locales: { pt },
      current: 'pt',
    },
});
