<template>
  <v-app>
    <!-- <router-view endtime="endtime" /> -->
    <router-view/>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //endtime: 'Sep 8, 2020 16:37:25',
  }),
  
}
</script>
