<template>
  <v-container fluid class="black pa-0" dark
    style="background-image: linear-gradient(to top right, rgba(34, 53, 79, 1), rgba(244, 219, 181, 1);"
  >
    <v-row class="justify-center">
      <v-col
        cols="11"
        md="10"
      >

        <v-row 
          class="pt-6 pb-6 justify-center white--text"
          style="position: relative"
        >
          <v-col 
            cols="12" md="10"
            class="text-center"
          >
            <div class="pt-16 text-h4" style="line-height: 1">
              <span class="open-sans font-weight-regular background--text">Sobre Nós</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="pt-3 pb-16">
          <v-col
            cols="12"
            md="6"
            class="pb-12 text-center "
            v-for="(e, i) in equipe"
            :key="i"
          >
            <v-card
              flat
              color="transparent"
              class="fill-height pb-12"
            >
              <div class="flex-column fill-height">
                <div class="">
                  <v-avatar size="90%"><v-img :src="getImg(e.foto)" /></v-avatar>
                </div>
                <div class="pt-6 pb-3 text-h5">
                  <span class="poppins primary--text">{{ e.nome }}</span>
                </div>
                <div class="px-3 text-subtitle-1" style="line-height: 1.3">
                  <span class="poppins font-weight-normal background--text">{{ e.minibio }}</span>
                </div>
              </div>
              <!-- <v-card-actions class="py-0 justify-center">
                <div
                  v-for="(i,j) in e.social"
                  :key="j"
                  class="px-1"
                >
                  <v-btn 
                    icon
                    :href="i.link"
                    target="_blank"
                  >
                    <v-icon size="36" color="primary">{{ i.icone }}</v-icon>
                  </v-btn>
                </div>
              </v-card-actions> -->
            </v-card>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>
// import Contador from '../components/Contador'
// import Inscricao from '../components/Inscricao'

export default {
  components: {
    // Contador,
    // Inscricao
  },

  //props: ['endtime'],

  data: () => ({
    capa: 'bg-capa.jpg',
    equipe: [
      {
        foto: 'paulo.jpg',
        nome: 'Dr. Paulo Barreto',
        minibio: 'Advogado e Contador. MBA em Auditoria e Perícia Contábil. Pós-graduado em Direito Público, Direito Previdenciário e Gestão de Condomínios. Especialista em Direito Imobiliário e Direito Bancário. Ex-Capitão-Tenente da Marinha do Brasil. Membro da Comissão de Direito Imobiliário e Condominal da Ordem dos Advogados de Brasil - RN.',
        social: [
          {
            icone: 'mdi-instagram',
            link: 'https://'
          },
          {
            icone: 'mdi-linkedin',
            link: 'https://'
          }
        ]
      },
      {
        foto: 'jussiara.jpg',
        nome: 'Dra. Jussiara Barreto',
        minibio: 'Advogada. Pós-graduada em Licitações e Contratos Públicos. Pós-graduada em Direito Constitucional. Especialista no Direito das Famílias e Sucessões. Membro do Instituto Brasileiro de Direito de Família - IBDFAM. Trabalhou como assessora jurídica do SAAE-Serviço de Águas e Esgotos no município de Maxaranguape/RN.',
        social: [
          {
            icone: 'mdi-instagram',
            link: 'https://'
          },
          {
            icone: 'mdi-linkedin',
            link: 'https://'
          }
        ]
      },
    ]
  }),

  methods: {
    getImg(i) {
      return require('../assets/img/'+i)
    },
  },

  mounted() {
    
  }

}
</script>
