<template>
  <!-- <v-app-bar
    app flat dark absolute
    color="grey darken-4"
    height="150"
  > -->
  <v-app-bar
    app flat dark absolute
    color="black"
    height="150"
  >
    <div class="pt-0 mx-auto">
      <v-img
        class="mx-auto"
        contain
        :src="getImg(logo)"
        transition="scale-transition"
        width="170"
      />
    </div>
  </v-app-bar>
</template>

<script>
  export default {
    name: 'Navbar',

    data: () => ({
      logo: 'logo.png', 
    }),

    methods: {
      getImg(i) {
        return require('@/assets/img/'+i)
      },
    },
  }
</script>
