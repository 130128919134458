<template>
  <v-container fluid class="py-16 grey lighten-4" dark>

    <v-row class="pt-12 justify-center">
      <v-col cols="12" md="8" class="text-center">
        <div class="text-h4">
          <span class="poppins secondary--text font-weight-regular">Áreas de Atuação</span>
        </div>
        <div class="pt-3 text-h6">
          <span class="poppins secondary--text font-weight-regular">Oferecemos assessoria jurídica consultiva e contenciosa para as seguintes áreas:</span>
        </div>
      </v-col>
    </v-row>

    <v-row 
      class="py-12 px-6 justify-center white--text"
    >
      <v-col 
        cols="12" md="10"
        class="text-center"
      >
        <v-row>
          <v-col
            cols="12" md="12"
            class="pb-16"
          >
            <v-expansion-panels class="mb-6">
              <v-expansion-panel
                v-for="(a, i) in areas"
                :key="i"
              >
                <v-expansion-panel-header expand-icon="mdi-menu-down" class="">
                  
                  <span class="poppins font-weight-medium">
                    <v-avatar color="grey lighten-4" class="mr-3">
                      <v-icon color="primary darken-2">{{ a.icone }}</v-icon>
                    </v-avatar>
                    <div class="d-inline">
                      <span class="">{{ a.nome }}</span>
                    </div>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="text-left">
                  <div class="poppins pb-4" v-html="a.descricao">
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<style >

</style>

<script>

export default {

  data: () => ({
    areas: [
      {
        icone: 'mdi-human-male-child',
        nome: 'Direito das Famílias',
        descricao: '<p>Atuação na área judicial, bem como extrajudicial, esta na esfera consensual, intentando todos os esforços na resolução dos conflitos, de modo a evitar o desgaste emocional das partes envolvidas.</p>\n\
          • Não obtendo êxito na esfera extrajudicial, elaboração de estratégias contenciosas, por meio de medidas judiciais, a fim de resguardar o melhor interesse do cliente.\n\
          <br>• Atendimento humanizado, respeitando os sentimentos e priorizando o sigilo à intimidade dos envolvidos.\n\
          <br>• Divórcios consensual e litigioso;\n\
          <br>• Reconhecimento e Dissolução de União Estável;\n\
          <br>• Partilha de Bens;\n\
          <br>• Pensão Alimentícia (fixação, revisão, exoneração e execução);\n\
          <br>• Guarda, Regulamentação do Regime de Convivência, Reconhecimento de Filiação Biológica e afetiva;\n\
          <br>• Exercício da guarda do poder familiar;\n\
          <br>• Planejamento patrimonial;\n\
          <br>• Alteração de Regime de Bens;\n\
          <br>• Contrato de União Estável e Pacto Antenupcial;\n\
          <br>• Contrato de Namoro;\n\
          <br>• Interdição;\n\
          <br>• Alteração de nome ou sobrenome;\n\
          <br>• Tutela/Curatela e Adoção;\n\
          <br>• Busca e Apreensão de Menor.',
      }, 
      {
        icone: 'mdi-graph',
        nome: 'Direito Sucessório',
        descricao: '<p>Direito das Sucessões são normas jurídicas que regulam a transferência do patrimônio do falecido aos seus herdeiros, podendo essa transferência se dar em virtude de Lei ou de testamento.</p>\n\
          Atuação com sensibilidade nos processos de inventário, desde a sua abertura até a partilha dos bens, obedecendo o direito dos legítimos sucessores, herdeiros e legatários.\n\
          <br>• Inventários extrajudiciais e judiciais;\n\
          <br>• Testamento em suas variadas formas, revogação e rompimento;\n\
          <br>• Planejamento Sucessório;\n\
          <br>• Arrolamento judicial;\n\
          <br>• Exclusão de herdeiro;\n\
          <br>• Alvarás;\n\
          <br>• Adjudicações;\n\
          <br>• Planejamento sucessório;\n\
          <br>• Doações.',
      },
      {
        icone: 'mdi-home-group',
        nome: 'Direito Condominial',
        descricao: '<p>O direito condominial estipula as regras do uso normal de uma propriedade, e aplica as penalidades às pessoas que as desrespeitarem. Sendo assim, é dever do condômino respeitar às leis do código civil e da legislação condominial, que tratam do tema e regulam os direitos e deveres em condomínio.</p>\n\
          Logo, é trabalho do advogado especialista em direito condominial tratar das seguintes atividades:\n\
          <br>• Assessoria para o Síndico e Conselhos Pareceres Jurídicos\n\
          <br>• Elaboração e Revisão de Convenção Condominial e Regimento/Regulamento Interno;\n\
          <br>• Criação de medidas extrajudiciais e judiciais para reduzir a inadimplência dos clientes e cobranças;\n\
          <br>• Assessoria na área trabalhista voltada a empresas terceirizadas e funcionários próprios; Acompanhamento de Reuniões;\n\
          <br>• Relações Entre Síndicos e Condôminos; Questões Envolvendo Animais de Estimação; e Cobrança eficiente de inadimplentes.',
      },
      {
        icone: 'mdi-home-city',
        nome: 'Direito Imobiliário',
        descricao: '<p>As operações imobiliárias tornam-se cada vez mais complexas e uma ampla assessoria jurídica é condição determinante para o sucesso do empreendimento, do negócio jurídico e do planejamento imobiliário. Atendemos todas as áreas envolvendo o direito imobiliário abaixo:</p>\n\
          • Usucapião\n\
          <br>• Usufruto\n\
          <br>• Regularização de imóveis\n\
          <br>• Ações relativas a bens imóveis Ação de reintegração de posse Ação de adjudicação compulsória\n\
          <br>• Ação de resolução contratual\n\
          <br>• Elaboração de contrato\n\
          <br>• Incorporação imobiliária\n\
          <br>• Assessoria para imobiliárias, construtoras e incorporadoras',
      },
      {
        icone: 'mdi-cart',
        nome: 'Direito do Consumidor',
        descricao: 'A batalha nas relações jurídicas entre fornecedores e consumidores de bens e serviços vem de longa data sofrendo constantes mudanças, às quais desafiam a modernização das leis para amenizar e equilibrar as relações de consumo.\n\
          <br>• Sendo assim, o consumidor tem o direito de exigir que tudo o que for anunciado seja cumprido. Se o que foi prometido no anúncio não for cumprido, o consumidor tem direito de cancelar o contrato e receber a devolução da quantia que havia pago. A publicidade enganosa e a abusiva são proibidas pelo Código de Defesa do Consumidor. Abaixo citamos algumas áreas atuantes do nosso escritório:\n\
          <br>• Defesa em processo de execução;\n\
          <br>• Limitação de descontos de empréstimos a 35% do salário líquido (RMC); Inscrições indevidas no Serasa/SCPC;\n\
          <br>• Cobranças Indevidas;\n\
          <br>• Cobranças Abusivas;\n\
          <br>• Problemas com compras realizadas pela internet;\n\
          <br>• Produtos não entregues ou serviços não prestados;\n\
          <br>• Exclusão do nome do SERASA e SCPC;\n\
          <br>• Revisão de Contratos Bancários.',
      },
      {
        icone: 'mdi-bank',
        nome: 'Direito Bancário',
        descricao: '<p>Direito Bancário é ramo do Direito Privado especializado no tratamento do dinheiro, das instituições vocacionadas a trabalhar com ele assim como as relações que nascem do contato destes entes com o indivíduo.</p>\n\
          O advogado especialista em Direito Bancário elabora e analisa os contratos de empréstimos, abertura de crédito rotativo, conta digital, cartão de crédito, cédulas de crédito bancárias, cessão e alienação fiduciária de direitos creditórios, entre outros.',
      },
      {
        icone: 'mdi-office-building',
        //icone: 'mdi-amrgin',
        nome: 'Direito Empresarial',
        descricao: '<p>Oferecemos amplos serviços de consultoria, por meio da advocacia de COMPLIANCE EMPRESARIAL, permitindo que sejam identificadas deficiências da empresa contratante e sejam apontadas soluções, de cunho jurídico, que gerem maior segurança e melhores resultados ao cliente.</p>\n\
          • Assessoria jurídica preventiva Assessoria jurídica de crédito\n\
          <br>• Assessoria jurídica Fiscal\n\
          <br>• Assessoria jurídica Comercial\n\
          <br>• Assessoria jurídica Trabalhista\n\
          <br>• Identificação, análise e prevenção de riscos\n\
          <br>• Alinhamento da conduta da empesa segundo as Leis em vigor\n\
          <br>• Elaboração de normas de conduta e códigos de ética da empresa\n\
          <br>• Análise e elaboração de contratos de quaisquer naturezas\n\
          <br>• Processos contenciosos judiciais e administrativos\n\
          <br>• Renegociação de créditos e débitos',
      },
      {
        icone: 'mdi-medal',
        nome: 'Direito Militar',
        descricao: '<p>O advogado militar é aquele profissional especializado em pautas do Direito Militar, o ramo do Direito relacionado à legislação das Forças Armadas. Abaixo citamos algumas áreas atuantes do nosso escritório:</p>\n\
          • Pensão militar\n\
          <br>• Reforma do militar\n\
          <br>• Acidente em serviço\n\
          <br>• Reintegração do militar\n\
          <br>• Auxílio Invalidez\n\
          <br>• Previdência Militar',
      },
    ],
  }),

  methods: {
    getImg(i) {
      return require('../assets/img/'+i)
    },
  },
}
</script>
