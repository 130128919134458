<template>
  <v-container fluid class="pa-0 black" dark>

    <v-row class="pa-0 ma-0 pb-0 justify-center">
      <v-col cols="12" md="6" class="pa-0 text-center white--text" :order="$vuetify.breakpoint.smAndDown ? 1 : 0">
        <v-img
          :src="getImg(sobre)"
          class=""
          min-height="100%"
        />
      </v-col>
      <v-col cols="12" md="6" class="pa-9 py-16 ma-0 background white--text text-left">
        <div 
          class="text-h5 grey--text text--darken-3" 
          :class="{
            '': $vuetify.breakpoint.smAndDown,
            'pt-4': $vuetify.breakpoint.mdAndUp,
          }"
          style="line-height: 1.5"
        >
          <span class="raleway">Prezamos por estratégias criativas e eficazes aos clientes, contando com uma equipe de profissionais experientes.</span>
        </div>
        <div 
          class="text-h5 grey--text text--darken-3"
          :class="{
            'text-subtitle-1 pt-4': $vuetify.breakpoint.smAndDown,
            'text-subtitle-1 pt-6 pr-6': $vuetify.breakpoint.mdAndUp,
          }"
          style="line-height: 1.7"
        >
          <span class="raleway">Nossa equipe é composta por profissionais multidisciplinares com sólida formação, contemplando conhecimento em diversas áreas do Direito.</span>
        </div>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

export default {
  data: () => ({
    //brinde: 'brinde2.jpg'
    //brinde: 'tacas.jpg'
    sobre: 'sobre.jpg'
  }),

  methods: {
    getImg(i) {
      return require('../assets/img/'+i)
    },
  },
}
</script>
