<template>
  <v-fab-transition>
    <v-btn 
      class="ma-3"
      color="#00ba25"
      dark
      fab
      fixed
      bottom
      right large
      :href="whatsapp"
      target="_blank"
    >
      <v-badge
        color="red"
        right
        top
      >
        <template v-slot:badge>
          1
        </template>
          <v-icon large>
          mdi-whatsapp
        </v-icon>

      </v-badge>

    </v-btn>
  </v-fab-transition>
</template>

<script>

export default {
  props:['whatsapp'],
  
  data: () => ({
    
  }),

  methods: {
    getImg(i) {
      return require('../assets/img/'+i)
    },
  },
}
</script>
