<template>
  <v-container id="contato" fluid class="py-16 white" dark>

    <v-row class="pt-12 justify-center">
      <v-col cols="12" md="8" class="text-center">
        <div class="text-h3">
          <span class="poppins secondary--text font-weight-medium">Borges & Barreto</span>
        </div>
        <div class="pt-3 text-h6">
          <span class="poppins secondary--text font-weight-regular">Conte-nos o seu caso!</span>
        </div>
      </v-col>
    </v-row>

    <v-row 
      class="pt-9 pb-16 px-6 justify-center white--text"
    >
      <v-col
        cols="11"
        md="6"
      >
        <v-row class="justify-center">
          <v-col 
            cols="12"
            md="8"
            class="text-center"
          >
            <div class="secondary--text font-weight-medium">
              <div class="pb-2"><span><v-icon x-large color="secondary">mdi-whatsapp</v-icon></span></div>
              <div class="text-h6"><span>WhatsApp</span></div>
              <!-- <div class="d-inline"><span>(84) 99665-2333</span></div> -->
              <div class="body-2"><span>(84) 99893-0544</span></div>
            </div>
            <div class="pt-2 pb-4 secondary--text font-weight-medium">
              <v-btn
                color="secondary"
                block
                x-large
                height="54"
                class="text-h6 font-weight-medium"
                :href="whatsapp"
                target="_blank"
              >
                <v-icon large class="pr-2">mdi-whatsapp</v-icon>
                Falar pelo WhatsApp
              </v-btn>
            </div>
          </v-col>
          <v-col 
            cols="12"
            md="8"
            class="text-center"
          >
            <div class="text-h6 secondary--text font-weight-medium">
              <div class="pb-2"><span><v-icon x-large color="secondary">mdi-email</v-icon></span></div>
              <div class="text-h6"><span>E-mail</span></div>
              <div class="body-2"><span>contato@borgesebarreto.com.br</span></div>
            </div>
          </v-col>
          <v-col 
            cols="12"
            md="8"
            class="pt-6 text-center"
          >
            <div class=" secondary--text font-weight-medium">
              <div class="pb-2"><span><v-icon x-large color="secondary">mdi-map</v-icon></span></div>
              <div class="text-h6"><span>Escritório Natal/RN</span></div>
            </div>
            <div class="secondary--text font-weight-normal">
              <div class="text-body-2"><span>Tirol Office</span></div>
              <div class="text-body-2"><span>Rua Jaguarari, nº 2281, Lagoa Nova</span></div>
              <div class="text-body-2"><span>CEP 59.062-500</span></div>
            </div>
            <div class="pt-2 pb-4 secondary--text font-weight-medium">
              <v-btn
                color="secondary"
                block
                x-large
                height="54"
                class="text-h6 font-weight-medium"
                :href="maps"
                target="_blank"
              >
                <v-icon large class="pr-1">mdi-google-maps</v-icon>
                Abrir no Google Maps
              </v-btn>
            </div>
          </v-col>
          <v-col 
            cols="12"
            class="pt-6 text-center"
          >
            <div class="pt-3 text-caption secondary--text font-weight-normal">
              <div class="text-h6"><span>Conheça seus direitos em nossas redes sociais</span></div>
              <!-- <div class="text-uppercase"><span>Siga-nos nas redes sociais</span></div> -->
            </div>
            <div class="text-h6 pt-2 pb-4 secondary--text font-weight-medium">
              <div class="px-2 d-inline">
                <v-btn icon :href="instagram" target="_blank">
                  <span><v-icon x-large color="secondary">mdi-instagram</v-icon></span>
                </v-btn>
              </div>
              <div class="px-2 d-inline">
                <v-btn icon :href="facebook" target="_blank">
                  <span><v-icon x-large color="secondary">mdi-facebook</v-icon></span>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col 
            cols="12"
            class="text-left"
          >
            
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

export default {

  props:['whatsapp'],

  data: () => ({
    maps: "https://goo.gl/maps/djgBnEEiHkHwG1hK6",
    instagram: 'https://instagram.com/borgesebarretoadv',
    facebook: 'https://facebook.com/borgesebarretoadv',
  }),

  methods: {
    getImg(i) {
      return require('../assets/img/'+i)
    },
  },
}
</script>
