<template>
  <v-row>
    <v-col>
      <v-img
        :src="getImg(parallax)" 
        height="500"
        class=""
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  data () {
    return {
      parallax: 'parallax.jpg'
    }
  },
  methods: {
    getImg(i) {
      return require('../assets/img/'+i)
    },
  }
}
</script>